const moneyFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const dollars = (dollars: number) => moneyFormatter.format(dollars)
export const cents = (cents: number) => dollars(cents / 100)

export function number(x: number, decimalDigits?: number) {
  if (decimalDigits === undefined) decimalDigits = 0
  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
  })
  return formatter.format(x)
}
