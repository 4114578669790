import * as React from "react"
import { useAuth0 } from "@auth0/auth0-react"

import LoginButtonSimple from "./LoginButtonSimple"

const LoginButton = () => {
  const { isAuthenticated, logout, user } = useAuth0()

  if (isAuthenticated) {
    return (
      <button
        onClick={() => logout({ returnTo: window.location.origin })}
        className="inline-block rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-base font-medium text-white hover:bg-indigo-700"
      >
        Sign out, {user?.nickname}
      </button>
    )
  } else {
    return <LoginButtonSimple label="Sign in" />
  }
}

export default LoginButton
