import React from "react"
import { useParams } from "react-router-dom"
import { withAuthenticationRequired } from "@auth0/auth0-react"

import MessageBox from "./MessageBox"
import Stylist from "./Stylist"

const Messages: React.FC = () => {
  const { stylistUuid } = useParams()

  if (stylistUuid === undefined) {
    console.log("Message", { stylistUuid })
    return <div>Message is its path parameter!</div>
  }

  return (
    <div className="mx-auto max-w-7xl">
      <div className="chat-container flex flex-1 flex-col justify-between">
        <Stylist stylistUuid={stylistUuid} />
        <MessageBox stylistUuid={stylistUuid} />
      </div>
    </div>
  )
}

export default withAuthenticationRequired(Messages)
