type VariableProps = JSX.IntrinsicElements["span"] & {
  isLoading?: boolean
  width?: string
}

const Variable: React.FC<VariableProps> = props => {
  let { isLoading, width, ...pruned } = props
  width = width != null ? width : "10ch"

  if (!isLoading) {
    return <span {...pruned} />
  } else {
    return (
      <span className="animate-pulse">
        <div
          className="h-4 rounded bg-gray-200"
          style={{ display: "inline-block", width }}
        />
      </span>
    )
  }
}

export default Variable
