import React from "react"

type FieldsetProps = { children: any[] }

const Fieldset: React.FC<FieldsetProps> = props => {
  return (
    <fieldset className="mb-4 rounded-md border border-gray-200 pl-4 pr-4 pb-4 pt-2 shadow-sm">
      {props.children}
    </fieldset>
  )
}

export default Fieldset
