import { debounce } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"

import { DataCallback, RequestCallback } from "../types"
import { addParams } from "../util"

import { useAddress } from "./address"
import { useDateSelection } from "./dateSelection"
import { useOrderedEffect } from "./ordered"
import { usePaymentMethod } from "./payment"
import { usePersistent } from "./persistent"
import { useServiceSelection } from "./serviceSelection"
import { useStylist } from "./stylist"

export {
  useAddress,
  useDateSelection,
  useOrderedEffect,
  usePaymentMethod,
  usePersistent,
  useServiceSelection,
  useStylist,
}

export const useApiCallback = <ArgsT, RetT>(
  requestCallback: RequestCallback,
  dataCallback: DataCallback,
  apiCallback: (a0: ArgsT) => Promise<RetT>,
  apiArgs: ArgsT,
  guard?: any[]
) => {
  const fetchData = async () => {
    try {
      requestCallback()
      const responseData = await apiCallback(apiArgs)
      dataCallback(true, responseData)
    } catch (error) {
      console.log(error)
      dataCallback(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, guard || [])
}

export const useApi = <ArgsT, RetT>(
  apiCallback: (a0: ArgsT) => Promise<RetT>,
  apiArgs: ArgsT,
  guard?: any[]
): { data?: RetT; isLoading: boolean; error: boolean } => {
  const [data, setData] = useState<RetT>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setIsError] = useState<boolean>(false)

  const callback: DataCallback = (okay, data) => {
    setIsError(!okay)
    setData(data)
    setIsLoading(false)
  }

  useApiCallback<ArgsT, RetT>(
    () => setIsLoading(true),
    callback,
    apiCallback,
    apiArgs,
    guard
  )

  return { data, isLoading, error }
}

export const useGetCallback = (
  requestCallback: RequestCallback,
  dataCallback: DataCallback,
  path: string,
  params?: Record<string, any>,
  guard?: any[]
) => {
  const { getAccessTokenSilently } = useAuth0()

  const url = new URL(path, process.env.REACT_APP_BASE_API_URL)
  if (params) addParams(url, params)

  const fetchData = async () => {
    try {
      requestCallback()
      const token = await getAccessTokenSilently()
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const responseData = await response.json()
      dataCallback(true, responseData)
    } catch (error) {
      console.log(error)
      dataCallback(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, guard || [])
}

export const useGet = (
  url: string,
  params?: Record<string, any>,
  guard?: any[]
) => {
  const [data, setData] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setIsError] = useState<boolean>(false)

  const callback: DataCallback = (okay, data) => {
    setIsError(!okay)
    setData(data)
    setIsLoading(false)
  }

  useGetCallback(() => setIsLoading(true), callback, url, params, guard)

  return { data, isLoading, error }
}

export const useGeocoder = (initialValue: string) => {
  const [value, setValue] = useState(initialValue)
  const [suggestions, setSuggestions] = useState([])
  const [error, setError] = useState(false)

  const [needUpdate, setNeedUpdate] = useState(false)
  const delay = 300
  const setNeedUpdateDebounced = useCallback(debounce(setNeedUpdate, delay), [
    setNeedUpdate,
    delay,
  ])

  const handleChange = (e: any) => {
    setValue(e.target.value)
    setNeedUpdateDebounced(true)
  }

  type Result =
    | {
        state: "current"
      }
    | {
        state: "error"
      }
    | {
        state: "loaded"
        results: any
      }

  function send(isLast: boolean, data: Result) {
    switch (data.state) {
      case "current":
        break

      case "error":
        setError(true)
        break

      case "loaded":
        setSuggestions(data.results)
        break

      default:
        // If this fails to type-check, you've failed to include a case above.
        const never: never = data
    }
  }

  async function load(send: (a0: Result) => void) {
    if (!needUpdate) return { state: "current" }
    setNeedUpdate(false)
    try {
      const url = new URL(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json`
      )
      addParams(url, {
        access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        autocomplete: true,
        proximity: "ip",
        types: "address",
      })
      const response = await fetch(url)
      const results = await response.json()
      const filtered = results.features.filter((f: any) =>
        Object.hasOwnProperty.call(f, "address")
      )
      send({ state: "loaded", results: filtered })
    } catch (error) {
      console.log("Error fetching data, ", error)
      send({ state: "error" })
    }
  }

  useOrderedEffect("geocoder", load, send, [value, needUpdate])

  return {
    fieldProps: {
      value,
      // onChange: debounce(handleChange, 200),
      onChange: handleChange,
    },
    setValue: (v: any) => {
      setNeedUpdate(false)
      setValue(v)
    },
    suggestions,
    setSuggestions,
    error,
  }
}
