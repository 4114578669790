import React, { useLayoutEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"

import Header from "../components/Header"
import Footer from "../components/Footer"
import "./layout.css"

const Layout: React.FC = () => {
  const location = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div className="min-h-96 h-full bg-gray-100">
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

export default Layout
