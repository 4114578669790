import NotificationEmail from "../components/NotificationEmail"
import NotificationPhone from "../components/NotificationPhone"
import ToSLink from "../components/ToSLink"

type NotificationNoticeProps = {
  suppressToS?: boolean
}

const NotificationNotice: React.FC<NotificationNoticeProps> = ({
  suppressToS,
}) => {
  return (
    <p className="mt-4 text-sm text-gray-500">
      By submitting, you authorize Clexi to send you messages through the
      channels that you select. E-mail messages will come from{" "}
      <NotificationEmail />. SMS messages will come from <NotificationPhone />.
      Message/data rates apply.
      {suppressToS || (
        <>
          {" "}
          See <ToSLink />.
        </>
      )}
    </p>
  )
}

export default NotificationNotice
