const ToSLink: React.FC = () => {
  return (
    <a
      className="text-indigo-600 underline"
      href="https://clexi.com/terms"
      rel="noreferrer"
      target="_blank"
    >
      Terms of Service
    </a>
  )
}

export default ToSLink
