import React from "react"

import { ClientsService } from "../api"
import { useApi } from "../hooks"

import Select from "./Select"

import type { ChangeEvent } from "react"

type TimezoneFieldProps = {
  isLoading?: boolean
  onChange?: (a0: string) => void
  value?: string
}

const TimezoneField: React.FC<TimezoneFieldProps> = props => {
  const { data, isLoading, error } = useApi(
    ClientsService.retrieveClientTimezoneList,
    {}
  )

  function forward(e: ChangeEvent<HTMLSelectElement>) {
    if (props.onChange != null) props.onChange(e.target.value)
  }

  return (
    <>
      {!error && (
        <Select
          isLoading={isLoading || props.isLoading}
          onChange={forward}
          value={props.value}
          disabled
        >
          {data &&
            data.all.map(z => (
              <option key={z.name} value={z.name}>
                {z.name} ({z.offset})
              </option>
            ))}
        </Select>
      )}
    </>
  )
}

export default TimezoneField
