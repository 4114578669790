/* TODO: Add isLoading support to this component.

   The difficulty is that, right now, we need the defaultValue to be provided from the
   beginning, but that might not be available until loading is complete.
 */

import classNames from "classnames"
import React from "react"

import { useGeocoder } from "../hooks"

import Input from "./Input"

type GeoCoderProps = {
  handleGeocoderValue?: (a: any) => void
  defaultValue?: string | null
  disabled?: boolean
}

const GeoCoder: React.FC<GeoCoderProps> = props => {
  const geocoder = useGeocoder(props.defaultValue || "")
  const { handleGeocoderValue } = props

  const dropdownCls = classNames(
    "w-full z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow",
    { hidden: geocoder.suggestions?.length === 0 }
  )

  return (
    <>
      <div className="relative">
        <Input
          disabled={props.disabled}
          type="text"
          name="Address"
          placeholder="Address"
          {...geocoder.fieldProps}
          className="mt-1 block h-10 w-full rounded-md border border-gray-200 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        {geocoder.suggestions?.length > 0 && (
          <div id="dropdown" className={dropdownCls}>
            {geocoder.suggestions.map((suggestion: any) => {
              return (
                <li
                  key={suggestion.place_name}
                  className="flex cursor-pointer py-4 hover:bg-gray-100"
                  onClick={() => {
                    handleGeocoderValue && handleGeocoderValue(suggestion)
                    geocoder.setValue(suggestion.place_name)
                    geocoder.setSuggestions([])
                  }}
                >
                  <div className="ml-3">
                    <p className="text-sm text-gray-500">
                      {suggestion.place_name}
                    </p>
                  </div>
                </li>
              )
            })}
          </div>
        )}
      </div>
      {geocoder.error && <div>ERROR !</div>}
    </>
  )
}

export default GeoCoder
