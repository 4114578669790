import { DateTime } from "luxon"
import { useCallback, useEffect, useState } from "react"

import { activateAvailableSlot, ClientsService } from "../api"

import { usePersistent } from "./persistent"

import type { ActiveAvailableSlot, ClientInvitation } from "../api"
import type { Coordinates } from "../types"

type Args = {
  stylistUuid: string | null
  durationMinutes: number
  coordinates: Coordinates | null
  preferredDate: string
  /* invitation is undefined if there is no invitation, or null if there is but it
     hasn't loaded yet. */
  invitation?: ClientInvitation | null
}

type Ret = {
  isLoading: boolean
  slots: ActiveAvailableSlot[] | null
  selectionIdx: number | null
  setSelectionIdx: (a0: number) => void
  selectionDisabled: boolean
}

const sameSlot = (s1: ActiveAvailableSlot, s2: ActiveAvailableSlot) =>
  s1.start === s2.start && s1.end === s2.end

export const useDateSelection = ({
  stylistUuid,
  durationMinutes,
  coordinates,
  preferredDate,
  invitation,
}: Args): Ret => {
  const [isLoading, setIsLoading] = useState(false)
  const [slots, setSlots] = useState<ActiveAvailableSlot[] | null>(null)
  const [selectionDisabled, setSelectionDisabled] = useState(false)
  const [pickedSlot, setPickedSlot] = usePersistent<ActiveAvailableSlot | null>(
    "slot",
    null
  )
  let slotIdx: number | null = null

  if (slots != null && pickedSlot != null) {
    slotIdx = slots.findIndex(s => sameSlot(s, pickedSlot))
  }

  const loadSlots = useCallback(async () => {
    if (invitation !== undefined) {
      if (invitation === null) return

      if (invitation.start) {
        const start = invitation.start
        const startDateTime = DateTime.fromISO(start)
        const endDateTime = startDateTime.plus({ minutes: durationMinutes })
        const end = endDateTime.toISO()
        const bias = "NONE"
        setSlots([{ start, end, bias, startDateTime, endDateTime }])
        setSelectionDisabled(true)
        return
      }
    }

    if (
      stylistUuid == null ||
      durationMinutes === 0 ||
      !coordinates ||
      preferredDate === ""
    ) {
      setSlots(null)
      return
    }

    setIsLoading(true)

    const slots = await ClientsService.listAvailableSlots({
      stylistUuid,
      durationMinutes,
      preferredDate,
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
      candidateApptId: invitation?.id,
    })
    setSlots(slots.map(activateAvailableSlot))
    setIsLoading(false)
  }, [coordinates, durationMinutes, invitation, preferredDate, stylistUuid])

  useEffect(() => {
    loadSlots()
  }, [loadSlots])

  useEffect(() => {
    if (slots == null) return

    if (slots.length === 1) {
      setPickedSlot(slots[0])
      return
    }

    if (pickedSlot == null) return

    const idx = slots.findIndex(s => sameSlot(s, pickedSlot))
    setPickedSlot(idx === -1 ? null : slots[idx])
  }, [pickedSlot, setPickedSlot, slots])

  const setSelectionIdx = useCallback(
    (n: number) => {
      if (slots != null) setPickedSlot(slots[n])
    },
    [setPickedSlot, slots]
  )

  return {
    isLoading,
    slots,
    selectionIdx: slotIdx,
    setSelectionIdx,
    selectionDisabled,
  }
}
