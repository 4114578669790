import { useCallback, useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"

import { ClientsService } from "../api"

import type { PaymentMethodDetail } from "../api"

type usePaymentMethodArgs = {
  refreshCounter?: number
  stylistUuid?: string
}

export const usePaymentMethod = (args: usePaymentMethodArgs) => {
  const { refreshCounter, stylistUuid } = args
  const { isAuthenticated } = useAuth0()

  const [paymentMethodIsLoading, setPaymentMethodIsLoading] = useState(true)
  const [paymentMethodError, setPaymentMethodError] = useState(false)
  const [paymentMethodData, setPaymentMethodData] =
    useState<PaymentMethodDetail | null>(null)

  const loadPaymentMethod = useCallback(async () => {
    if (!isAuthenticated) {
      setPaymentMethodData(null)
      setPaymentMethodError(false)
      setPaymentMethodIsLoading(false)
      return
    }

    setPaymentMethodIsLoading(true)

    if (stylistUuid == null) return

    try {
      const paymentMethod =
        await ClientsService.retrievePaymentMethodForStylist({
          stylistUuid,
        })
      setPaymentMethodData(paymentMethod.detail || null)
      setPaymentMethodError(false)
    } catch (error) {
      console.log("Error fetching payment data", error)
      setPaymentMethodData(null)
      setPaymentMethodError(true)
    }

    setPaymentMethodIsLoading(false)
  }, [
    isAuthenticated,
    setPaymentMethodIsLoading,
    setPaymentMethodError,
    setPaymentMethodData,
    stylistUuid,
  ])

  useEffect(() => {
    loadPaymentMethod()
  }, [loadPaymentMethod, refreshCounter])

  return { paymentMethodIsLoading, paymentMethodError, paymentMethodData }
}
