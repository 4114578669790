import { Link } from "react-router-dom"

import logo from "../assets/logo.png"

import type { PropsWithChildren } from "react"

const Header: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <header className="bg-white">
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="flex w-full items-center justify-between border-b py-6 lg:border-none">
          <div className="flex items-center">
            <Link to="/bookings">
              <span className="sr-only">Clexi</span>
              <img src={logo} style={{ maxWidth: 120 }} alt="" />
            </Link>
          </div>
          {children}
        </div>
      </nav>
    </header>
  )
}

export default Header
