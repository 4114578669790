import React from "react"
import { RadioGroup } from "@headlessui/react"

import type { ActiveAvailableSlot } from "../api"

type DatePickerProps = {
  disabled?: boolean
  loading?: boolean
  onChange?: (a: number) => void
  slots: ActiveAvailableSlot[]
  value?: number
}

const DatePicker: React.FC<DatePickerProps> = props => {
  const selectedItem =
    props.value === undefined ? null : props.slots[props.value]

  function update(slot: ActiveAvailableSlot) {
    if (!props.onChange) return
    const i = props.slots.indexOf(slot)
    props.onChange(i)
  }

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ")
  }

  const formatStart = (slot: ActiveAvailableSlot): string =>
    slot.startDateTime.toFormat("EEE, ff")

  const formatEnd = (slot: ActiveAvailableSlot): string =>
    slot.endDateTime.toFormat("t ZZZZ")

  return (
    <>
      {!props.loading && (
        <RadioGroup
          disabled={props.disabled}
          value={selectedItem}
          onChange={update}
        >
          <div className="mt-4 space-y-4">
            {props.slots.map(suggestion => (
              <RadioGroup.Option
                key={formatStart(suggestion)}
                value={suggestion}
                className={({ checked, active }) =>
                  classNames(
                    checked ? "border-transparent" : "border-gray-300",
                    active ? "border-indigo-500 ring-2 ring-indigo-500" : "",
                    "relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <span className="flex items-center">
                      <span className="flex flex-col text-sm">
                        <RadioGroup.Label
                          as="span"
                          className="font-medium text-gray-900"
                        >
                          {formatStart(suggestion)} - {formatEnd(suggestion)}
                        </RadioGroup.Label>
                        {/* <RadioGroup.Description
                            as="span"
                            className="text-gray-500"
                            >
                            <span
                            className="hidden sm:mx-1 sm:inline"
                            aria-hidden="true"
                            >
                            &middot;
                            </span>{" "}
                            <span className="block sm:inline">
                            {suggestion.end}
                            </span>
                            </RadioGroup.Description> */}
                      </span>
                    </span>
                    {/* <RadioGroup.Description
                        as="span"
                        className="mt-2 flex text-sm sm:mt-0 sm:ml-4 sm:flex-col sm:text-right"
                        >
                        <span className="font-medium text-gray-900">
                        {suggestion.start}
                        </span>
                        <span className="ml-1 text-gray-500 sm:ml-0">/mo</span>
                        </RadioGroup.Description> */}
                    <span
                      className={classNames(
                        active ? "border" : "border-2",
                        checked ? "border-indigo-500" : "border-transparent",
                        "pointer-events-none absolute -inset-px rounded-lg"
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      )}
    </>
  )
}

export default DatePicker
