import React, { useEffect, useRef, useState } from "react"

type InputProps = JSX.IntrinsicElements["input"] & {
  isLoading?: boolean
}

const Input: React.FC<InputProps> = props => {
  const { isLoading, ...pruned } = props
  const [width] = useState(Math.round(Math.random() * 60 + 20))

  const indeterminateRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    const el = indeterminateRef.current
    if (el != null) el.indeterminate = true
  })

  const isCheckbox = pruned.type === "checkbox"
  const classes = isCheckbox
    ? "mr-2 box-border border rounded w-5 h-5 border-[#dfe4ea] text-[#3758f9]"
    : "mt-1 px-3 block w-full h-10 rounded-md border border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

  if (!isLoading) {
    return <input className={classes} {...pruned} />
  } else if (isCheckbox) {
    return (
      <span className="animate-pulse">
        <input
          ref={indeterminateRef}
          className={classes}
          {...pruned}
          disabled
        />
      </span>
    )
  } else {
    return (
      <span className="animate-pulse">
        <div className={classes}>
          <div
            className="mt-3 mb-2 h-4 rounded bg-gray-200"
            style={{ width: `${width}%` }}
          />
        </div>
      </span>
    )
  }
}

export default Input
