import { useCallback, useState } from "react"

import { Address } from "../util/Address"

import { usePersistent } from "./persistent"

import type { ClientInvitation } from "../api"
import type { Coordinates } from "../types"

type Profile = {
  street_address_parts: Address
}

type GeocoderValue = {
  place_name: string
  geometry: {
    coordinates: number[]
  }
}

type Args = {
  profile: Profile | null
  invitation?: ClientInvitation | null
}

export const useAddress = ({
  profile,
  invitation,
}: Args): {
  address: string | null
  addressUnit: string | null
  coordinates: Coordinates | null
  isInvitationAddress: boolean
  changeAddress: (a0: GeocoderValue) => void
  changeAddressUnit: (a0: string) => void
} => {
  const [address, setAddress] = usePersistent<string | null>("address", null)
  const [addressUnit, setAddressUnit] = usePersistent<string | null>(
    "unit",
    null
  )
  const [coordinates, setCoordinates] = usePersistent<Coordinates | null>(
    "coords",
    null
  )
  const [isInvitationAddress, setIsInvitationAddress] = useState(false)
  const [inited, setInited] = useState(false)

  function use(parts: any) {
    const addr = new Address(parts)
    setAddress(addr.fullLineNoUnit)
    setAddressUnit(addr.unit == null ? "" : addr.unit)
    setCoordinates(addr.coordinates)
  }

  // Can't just test parts for null, as it could come as an empty object.
  function isPopulated(parts: any) {
    return parts != null && Object.keys(parts).length > 0
  }

  if (!inited && profile != null) {
    if (invitation != null && isPopulated(invitation.street_address_parts)) {
      use(invitation.street_address_parts)
      setIsInvitationAddress(true)
    } else if (isPopulated(profile.street_address_parts)) {
      if (address == null) use(profile.street_address_parts)
    } else {
      if (address == null) {
        setAddress("")
        setAddressUnit("")
      }
    }
    setInited(true)
  }

  const changeAddress = useCallback(
    (geocoderValue: GeocoderValue) => {
      setAddress(geocoderValue.place_name)
      setCoordinates({
        longitude: geocoderValue.geometry.coordinates[0],
        latitude: geocoderValue.geometry.coordinates[1],
      })
    },
    [setAddress, setCoordinates]
  )

  return {
    address,
    addressUnit,
    coordinates,
    isInvitationAddress,
    changeAddress,
    changeAddressUnit: setAddressUnit,
  }
}
