/** Initializes and tracks services selections, tracking changes across navigation.

   Initial service selection is taken from an invitation, or a model appointment, or
   zeroes, in that order.  If from an invitation, no other services will be listed;
   otherwise, all services that the stylist provides will be listed.

   If you provide a model invitation, make sure that you do not provide only a stylist
   first (that will initialize the hook to zero-counts of stylist services).  Instead,
   pass the stylist only after the model appointment is available. */

import { useCallback, useMemo, useState } from "react"

import { usePersistent } from "./persistent"

import type {
  ClientAppointment,
  ClientInvitation,
  OfferedService,
  Stylist,
} from "../api"
import type { CountedOfferedService } from "../types"

type Props = {
  stylist?: Stylist | null
  modelAppointment?: ClientAppointment | null
  invitation?: ClientInvitation | null
}

type ServiceReq = {
  id: number
  cost_cents: number
  duration_minutes: number
}

type InitState = "none" | "inv" | "model" | "stylist"

export const useServiceSelection = ({
  stylist,
  modelAppointment,
  invitation,
}: Props): {
  countedServices: CountedOfferedService[]
  totalCostCents: number
  totalDurationMinutes: number
  payload: ServiceReq[]
  addService: (idx: number) => void
  removeService: (idx: number) => void
} => {
  const [countedServices, setCountedServices] = usePersistent<
    CountedOfferedService[]
  >("services", [])
  const [inited, setInited] = useState<InitState>("none")

  const withCount =
    (n: number) =>
    (s: OfferedService): CountedOfferedService =>
      Object.assign({}, s, { count: n })

  const sameAs = (target: OfferedService) => (query: OfferedService) =>
    query.id === target.id &&
    query.cost_cents === target.cost_cents &&
    query.duration_minutes === target.duration_minutes

  function finishInit(state: InitState, next: CountedOfferedService[]) {
    for (const s of countedServices) {
      const idx = next.findIndex(sameAs(s))
      if (idx === -1) continue
      next[idx].count = s.count
    }
    setCountedServices(next)
    setInited(state)
  }

  if (invitation != null) {
    if (inited !== "inv") {
      const services = invitation.services.map(withCount(1))
      finishInit("inv", services)
    }
  } else if (modelAppointment != null && stylist != null) {
    if (inited !== "model") {
      const services: CountedOfferedService[] = stylist.offered_services.map(
        withCount(0)
      )
      for (const ms of modelAppointment.services) {
        let idx = services.findIndex(sameAs(ms))
        if (idx === -1) idx = services.push(withCount(0)(ms)) - 1
        ++services[idx].count
      }
      finishInit("model", services)
    }
  } else if (stylist != null) {
    if (inited !== "stylist") {
      const services = stylist.offered_services.map(withCount(0))
      finishInit("stylist", services)
    }
  } else {
    if (inited !== "none") {
      console.warn(
        "useServiceSelection deiniting",
        inited,
        stylist,
        modelAppointment,
        invitation
      )
      setInited("none")
    }
  }

  const addService = useCallback(
    (idx: number) => {
      if (inited === "none") return
      const t = countedServices.slice()
      t[idx] = Object.assign({}, t[idx])
      ++t[idx].count
      setCountedServices(t)
    },
    [countedServices, inited, setCountedServices]
  )

  const removeService = useCallback(
    (idx: number) => {
      if (inited === "none") return
      const t = countedServices.slice()
      t[idx] = Object.assign({}, t[idx])
      if (--t[idx].count < 0) t[idx].count = 0
      setCountedServices(t)
    },
    [countedServices, inited, setCountedServices]
  )

  const { totalCostCents, totalDurationMinutes, payload } = useMemo(() => {
    let totalCostCents = 0
    let totalDurationMinutes = 0
    const payload: ServiceReq[] = []

    for (const s of countedServices) {
      const count = s.count
      totalCostCents += s.cost_cents * count
      totalDurationMinutes += s.duration_minutes * count
      for (let i = 0; i < count; i++) {
        payload.push({
          id: s.id,
          cost_cents: s.cost_cents,
          duration_minutes: s.duration_minutes,
        })
      }
    }

    return { totalCostCents, totalDurationMinutes, payload }
  }, [countedServices])

  return {
    countedServices,
    totalCostCents,
    totalDurationMinutes,
    payload,
    addService,
    removeService,
  }
}
