import ReactGA from "react-ga4"
import { Address } from "./Address"

export { Address }

export function addParams(url: URL, params: Record<string, any>) {
  const p = url.searchParams
  for (const [key, val] of Object.entries(params)) {
    p.append(key, val)
  }
}

export function sendGAEvent(
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean
) {
  ReactGA.event({
    category: category,
    action: action,
    label: label && label, // optional
    value: value && value, // optional
    nonInteraction: nonInteraction && nonInteraction, // optional
  })
}
