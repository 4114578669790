import React from "react"
import { useParams } from "react-router-dom"
import Button from "../components/Button"
import LoginButtonSimple from "../components/LoginButtonSimple"
import StylistCard from "../components/StylistCard"
import { useAuth0 } from "@auth0/auth0-react"
import { useStylist } from "../hooks"

const StylistProfile = () => {
  const { stylistUuid } = useParams()
  const { user } = useAuth0()

  const { stylistData, stylistError, stylistIsLoading } = useStylist(
    stylistUuid || null
  )

  return (
    <div className="mx-auto max-w-2xl px-4 py-12 sm:px-6 lg:px-8">
      <StylistCard
        stylistData={stylistData}
        loading={stylistIsLoading}
        error={stylistError}
      >
        <>
          {/* We always show the action buttons below, even for anonymous users, so
              that, for example, Stripe's internal reviewers don't get confused thinking
              the real profile is hidden behind a password.  However, we'll show this
              helpful message in that case. */}
          {user == null && (
            <p className="px-6 pb-6 text-white">
              To book an appointment or message this stylist, you need an
              account.
            </p>
          )}
          <div className="border-gray-200 px-6 pb-6">
            <div className="flex flex-wrap gap-4">
              <Button
                isLoading={stylistIsLoading}
                scheme="white"
                to={`/booking-detail/${stylistUuid}`}
              >
                Request new appointment
              </Button>
              <Button scheme="paleIndigo" to={`/messages/${stylistUuid}`}>
                Contact stylist
              </Button>
            </div>
          </div>
        </>
      </StylistCard>
    </div>
  )
}

export default StylistProfile
