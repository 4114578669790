import * as React from "react"
import { useAuth0 } from "@auth0/auth0-react"

import Button from "./Button"
import Spinner from "./Spinner"

import type { ButtonScheme } from "./Button"

type LoginButtonSimpleProps = {
  label: string
  scheme?: ButtonScheme
  signup?: boolean
}

const LoginButtonSimple: React.FC<LoginButtonSimpleProps> = ({
  label,
  scheme,
  signup,
}) => {
  const { isLoading, loginWithRedirect } = useAuth0()

  if (isLoading) {
    return (
      <Button disabled scheme={scheme}>
        <Spinner />
      </Button>
    )
  }

  return (
    <Button
      scheme={scheme}
      onClick={() =>
        loginWithRedirect({
          appState: { returnTo: window.location.pathname },
          screen_hint: signup ? "signup" : "login",
        })
      }
    >
      {label}
    </Button>
  )
}

export default LoginButtonSimple
