import { Link } from "react-router-dom"

import type React from "react"

export type ButtonScheme = "indigo" | "white" | "paleIndigo"

type ButtonProps = {
  children: React.ReactNode
  disabled?: boolean
  isLoading?: boolean
  scheme?: ButtonScheme
  to?: string
  onClick?: () => void
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  isLoading,
  scheme,
  to,
  onClick,
}) => {
  disabled = !!disabled || isLoading

  const commonClasses =
    "border font-medium px-4 py-2 rounded-md shadow-sm text-center text-sm"
  const commonStyle = {
    flexBasis: 0,
    flexGrow: 1,
    minWidth: "fit-content",
  }

  let schemeClasses
  switch (scheme) {
    case undefined:
    case "indigo":
      schemeClasses =
        "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
      break

    case "paleIndigo":
      schemeClasses =
        "border-transparent bg-indigo-500 text-white hover:bg-indigo-700 focus:ring-indigo-500"
      break

    case "white":
      schemeClasses =
        "bg-white border-gray-300 text-indigo-600 focus:ring-indigo-500 hover:bg-gray-50"
      break

    default:
      const never: never = scheme
      schemeClasses = never
  }

  let el
  if (to == null || disabled) {
    el = (
      <button
        type="button"
        className={`${commonClasses} ${schemeClasses}`}
        disabled={disabled}
        style={commonStyle}
        onClick={onClick}
      >
        {children}
      </button>
    )
  } else {
    el = (
      <Link
        to={to}
        type="button"
        className={`${commonClasses} focus:outline-none focus:ring-2 focus:ring-offset-2 ${schemeClasses}`}
        style={commonStyle}
      >
        {children}
      </Link>
    )
  }

  if (isLoading) el = <span className="animate-pulse">{el}</span>

  return el
}

export default Button
