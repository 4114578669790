import React from "react"

import ToSLink from "./ToSLink"

const Footer = () => {
  return (
    <footer>
      <div className="border-t border-dashed border-gray-200">
        <div className="mx-auto max-w-3xl py-8 px-4 text-center text-sm text-gray-500 sm:px-6 sm:text-left lg:max-w-7xl lg:px-8">
          <span className="block sm:inline">
            &copy; 2024 Clexi, Inc. All rights reserved. By using this site, you
            agree to our <ToSLink />.
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
