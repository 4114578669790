import StripeElement from "./StripeElement"
import Variable from "./Variable"

import type { PropsWithChildren } from "react"

import type { PaymentMethodDetail } from "../api"

const Card: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="mt-6 bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
      <h2 className="mb-6 text-xl font-bold tracking-tight text-gray-700">
        Payment method
      </h2>
      {children}
    </div>
  )
}

type PaymentCardProps = {
  isLoading: boolean
  error: boolean
  methodData: PaymentMethodDetail | null
  returnUrl?: string
  stylistUuid?: string
  subdued?: boolean
}

const PaymentCard: React.FC<PaymentCardProps> = ({
  error,
  isLoading,
  methodData,
  returnUrl,
  stylistUuid,
  subdued,
}) => {
  const Bottom: React.FC = () => {
    return (
      <>
        <p className="mt-3 text-gray-500">
          Your card is not charged until the end of an appointment.
        </p>
        <div className="mt-10 sm:w-1/3">
          <StripeElement
            hasPaymentMethod={methodData != null}
            isLoading={isLoading}
            returnUrl={returnUrl}
            stylistUuid={stylistUuid}
            subdued={!!subdued}
          />
        </div>
      </>
    )
  }

  if (error) {
    return (
      <Card>
        <p>
          There was an error loading your payment information. Please try again
          later.
        </p>
      </Card>
    )
  } else if (!isLoading && methodData == null) {
    return (
      <Card>
        <p className="mt-3 text-gray-500">
          You need to setup a payment method before you can confirm
          appointments.
        </p>
        <Bottom />
      </Card>
    )
  } else {
    return (
      <Card>
        <p className="mt-3 text-gray-500">
          Your saved card is a{" "}
          <b className="text-gray-800">
            <Variable isLoading={isLoading} width="4ch">
              {methodData?.provider}
            </Variable>
          </b>{" "}
          ending in{" "}
          <b className="text-gray-800">
            <Variable isLoading={isLoading} width="4ch">
              {methodData?.last4}
            </Variable>
          </b>
          .
        </p>
        <Bottom />
      </Card>
    )
  }
}

export default PaymentCard
