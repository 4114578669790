import { useState } from "react"
import { useLocation } from "react-router-dom"

export const usePersistent = <T>(
  key: string,
  defValue: T
): [T, (a0: T) => void] => {
  const location = useLocation()
  const persisted = sessionStorage.getItem(key)
  const unpacked = persisted == null ? { loc: null } : JSON.parse(persisted)

  if (unpacked.loc === location.pathname) {
    defValue = unpacked.val
  } else {
    sessionStorage.removeItem(key)
  }

  const [value, setValue] = useState<T>(defValue)

  function proxySetValue(newValue: T) {
    setValue(newValue)
    sessionStorage.setItem(
      key,
      JSON.stringify({
        loc: location.pathname,
        val: newValue,
      })
    )
  }

  return [value, proxySetValue]
}
