import { useCallback, useEffect, useState } from "react"

import { activateStylist, ClientsService } from "../api"

import type { ActiveStylist } from "../api"

export const useStylist = (uuid: string | null) => {
  const [stylistIsLoading, setStylistIsLoading] = useState(true)
  const [stylistError, setStylistError] = useState(false)
  const [stylistData, setStylistData] = useState<ActiveStylist>()

  const loadStylist = useCallback(async () => {
    if (uuid == null) return

    try {
      const p = ClientsService.retrieveStylist({ stylistUuid: uuid })
      setStylistData(activateStylist(await p))
    } catch (e) {
      console.log("Error fetching data, ", e)
      setStylistError(true)
    }

    setStylistIsLoading(false)
  }, [uuid])

  useEffect(() => {
    loadStylist()
  }, [loadStylist])

  return { stylistIsLoading, stylistError, stylistData }
}
