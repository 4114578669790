import { Coordinates } from "../types"

export class Address {
  zip: string
  state: string
  city: string
  street: string
  number: string
  unit?: string

  latitude: number
  longitude: number

  constructor(data: any) {
    this.zip = data.zip
    this.state = data.state
    this.city = data.city
    this.street = data.street
    this.number = data.number
    this.unit = data.unit
    this.latitude = data.latitude
    this.longitude = data.longitude
  }

  isPopulated() {
    return Object.keys(this).length > 0
  }

  get topLine() {
    const unit_s = this.unit === undefined ? "" : ` ${this.unit}`
    return `${this.number} ${this.street}${unit_s}`
  }

  get topLineNoUnit() {
    return `${this.number} ${this.street}`
  }

  get bottomLine() {
    return `${this.city} ${this.state} ${this.zip}`
  }

  get fullLine() {
    return `${this.topLine} ${this.bottomLine}`
  }

  get fullLineNoUnit() {
    return `${this.topLineNoUnit} ${this.bottomLine}`
  }

  get coordinates(): Coordinates {
    return {
      latitude: this.latitude,
      longitude: this.longitude,
    }
  }
}
