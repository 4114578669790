import React from "react"

type SelectProps = JSX.IntrinsicElements["select"] & {
  isLoading?: boolean
}

const Select: React.FC<SelectProps> = props => {
  const classes =
    "bg-gray-200 mt-1 px-3 block w-full h-10 rounded-md border border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

  if (!props.isLoading) {
    return <select className={classes} {...props} />
  } else {
    return (
      <span className="animate-pulse">
        <select className={classes} {...props} disabled={true}>
          <option value="">Loading…</option>
        </select>
      </span>
    )
  }
}

export default Select
