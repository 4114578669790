import { ExclamationCircleIcon } from "@heroicons/react/20/solid"

type NotificationMethodWarningProps = {
  showWarning: boolean
}

const NotificationMethodWarning: React.FC<NotificationMethodWarningProps> = ({
  showWarning,
}) => {
  if (!showWarning) return null

  return (
    <div className="mt-4 flex items-center gap-3 rounded-lg bg-amber-100 px-5 py-4 text-yellow-700">
      <div className="h-5 w-5 shrink-0">
        <ExclamationCircleIcon />
      </div>
      <p className="text-sm">
        Select at least one notification option to receive updates from your
        stylist. Missing notifications may lead to missed appointments and
        account deactivation.
      </p>
    </div>
  )
}

export default NotificationMethodWarning
