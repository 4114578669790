import { useEffect } from "react"

const DEBUG = false

type Ticket = {
  request: number
  response: number
}

const tickets: Record<string, Ticket> = {}

/**
 * Calls `work(fn)`; when it finishes, `work()` should call the passed `fn(arg)` (`arg`
 * can be any single argument).  If, when called, its call-order is after all other
 * finished calls under the same `name`, `fn()` will call `update(isLast, arg)` with the
 * same `arg` that was passed to it.  Thus, although `work()` always invokes the passed
 * function, that passed function ensures that `update()` is called only for the most
 * recent in-order completion of `work()`.
 */
export const useOrderedEffect = <ArgT>(
  name: string,
  work: (a0: (a0: ArgT) => void) => void,
  update: (isLast: boolean, arg: ArgT) => void,
  guard?: any[]
) => {
  if (!(name in tickets)) {
    tickets[name] = {
      request: 0,
      response: 0,
    }
  }

  const ticket = tickets[name]

  function wrap(x: number) {
    function wrapped(arg: ArgT) {
      const isLast = x === ticket.request
      const isFresh = isLast || x > ticket.response
      if (DEBUG) {
        console.log(
          "response",
          x,
          "/",
          ticket.request,
          isLast ? "last" : isFresh ? "fresh" : "stale",
          arguments
        )
      }
      if (!isFresh) return
      update(isLast, arg)
      ticket.response = x
    }
    return wrapped
  }

  function delegate() {
    const x = ++ticket.request
    if (DEBUG) console.log("request", x)
    work(wrap(x))
  }

  useEffect(delegate, guard || [])
}
