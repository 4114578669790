import React, { useRef, useState } from "react"

import { ClientsService } from "../api"

import Input from "./Input"

import type { ChangeEvent } from "react"

type Props = {
  disabled?: boolean
  isLoading?: boolean
  onChange?: (value: string, valid: boolean) => void
  value?: string
}

const PhoneNumberField: React.FC<Props> = ({
  disabled,
  isLoading,
  onChange,
  value,
}) => {
  function onChangeDelegate(value: string, valid: boolean) {
    if (onChange !== undefined) onChange(value, valid)
  }

  const [errors, setErrors] = useState<string[]>([])

  const checkRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  async function change(e: ChangeEvent<HTMLInputElement>) {
    const phoneNumber = e.target.value

    if (checkRef.current != null) {
      clearTimeout(checkRef.current)
      checkRef.current = null
    }

    if (phoneNumber == null || phoneNumber === "") {
      setErrors([])
      onChangeDelegate(phoneNumber, true)
      return
    }

    onChangeDelegate(phoneNumber, false)

    checkRef.current = setTimeout(async () => {
      let valid = true
      try {
        const data = await ClientsService.retrieveClientPhoneNumberValidation({
          phoneNumber,
        })
        valid = data.valid
        setErrors(valid ? [] : data.validation_messages)
      } catch (e) {
        valid = false
        setErrors(["Error validating phone number"])
      }
      onChangeDelegate(phoneNumber, valid)
    }, 300)
  }

  const extraClasses =
    errors.length === 0
      ? "border-gray-200 focus:border-indigo-500 focus:ring-indigo-500"
      : "border-red-300  focus:border-red-500    focus:ring-red-500 text-red-900 placeholder:text-red-300"

  return (
    <>
      <Input
        disabled={disabled}
        isLoading={isLoading}
        type="tel"
        defaultValue={value || ""}
        onChange={change}
        className={`mt-1 block h-10 w-full rounded-md border px-3 shadow-sm sm:text-sm ${extraClasses}`}
        placeholder="201-555-1212"
      />
      <p className="mt-1 h-5 text-sm text-red-600">{errors}</p>
    </>
  )
}

export default PhoneNumberField
