import { Link } from "react-router-dom"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid"

import Spinner from "./Spinner"

type State =
  | "needsAuth"
  | "needsInfo"
  | "loading"
  | "loaded"
  | "locked"
  | "empty"
  | "error"

type Props = {
  state: State
  needsAddress: boolean
  needsPreferredDate: boolean
  needsServices: boolean
  stylistUuid?: string | null
}

export const DateSectionText: React.FC<Props> = props => {
  const {
    state,
    needsAddress,
    needsPreferredDate,
    needsServices,
    stylistUuid,
  } = props

  const undone = (
    <ExclamationCircleIcon className="mr-1.5 inline h-6 text-yellow-600" />
  )
  const done = <CheckCircleIcon className="mr-1.5 inline h-6 text-green-600" />

  switch (state) {
    case "needsAuth":
      return <p className="text-gray-500">Sign up to pick a date.</p>

    case "needsInfo":
      return (
        <>
          <p className="mb-2 text-gray-500">To pick a date:</p>
          <ul className="text-gray-500">
            <li>{needsServices ? undone : done}Select some services</li>
            <li>{needsAddress ? undone : done}Enter your address</li>
            <li>
              {needsPreferredDate ? undone : done}Pick your preferred date
            </li>
          </ul>
        </>
      )

    case "loading":
      return <Spinner />

    case "loaded":
      return (
        <p className="text-gray-500">
          Here are the times that your stylist is available around your
          preferred date. If no time works for you, please{" "}
          <Link
            className="text-blue-800 underline"
            to={`/messages/${stylistUuid}`}
          >
            message your stylist
          </Link>
          .
        </p>
      )

    case "locked":
      return (
        <p className="text-gray-500">
          Here is the time selected by your stylist. If it doesn't work for you,
          please{" "}
          <Link
            className="text-blue-800 underline"
            to={`/messages/${stylistUuid}`}
          >
            message your stylist
          </Link>
          .
        </p>
      )

    case "empty":
      return (
        <p className="text-gray-500">
          Your stylist is not available around your preferred date. Please pick
          a different preferred date. If no time works for you, please{" "}
          <Link
            className="text-blue-800 underline"
            to={`/messages/${stylistUuid}`}
          >
            message your stylist
          </Link>
          .
        </p>
      )

    case "error":
      return <p>An error occurred. Please try again later.</p>

    default:
      const bad_state: never = state
      throw bad_state
  }
}
