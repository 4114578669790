import React from "react"

type PlusMinusButtonProps = {
  disabled?: boolean
  onDecrement?: () => void
  onIncrement?: () => void
  readOnly?: boolean
  value?: number
}

const PlusMinusButton: React.FC<PlusMinusButtonProps> = props => {
  const { disabled, onDecrement, onIncrement, readOnly } = props
  const value = props.value || 0

  return (
    <span className="isolate mr-3 inline-flex rounded-md shadow-sm">
      <button
        disabled={disabled || readOnly}
        type="button"
        onClick={onDecrement && (() => onDecrement())}
        className="relative inline-flex items-center border border-gray-300 bg-white px-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
      >
        -
      </button>
      <button
        disabled={disabled || readOnly}
        type="button"
        className="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
      >
        {value}
      </button>
      <button
        disabled={disabled || readOnly}
        onClick={onIncrement && (() => onIncrement())}
        type="button"
        className="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
      >
        +
      </button>
    </span>
  )
}

export default PlusMinusButton
