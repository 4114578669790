import React from "react"
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline"
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/24/solid"

const Install: React.FC = () => {
  return (
    <main className="text-page">
      <section className="top">
        <h1>Adding Clexi to your Home Screen</h1>

        <p>
          Clexi operates thru your web browser, it is not downloaded from an app
          store. You simply add it to your phone's home screen following the
          instructions for your phone type and browser below.
        </p>
      </section>

      <details>
        <summary>
          <h2>iPhone Users</h2>
        </summary>

        <h3>iOS 13 or newer</h3>
        <ol>
          <li>
            Tap the <q>Share/Send</q> icon
            <ArrowUpOnSquareIcon /> on the bottom menu bar.
          </li>
          <li>
            Tap <q>Add to Home</q> <PlusIcon /> (you may need to scroll further
            down to see this icon).
          </li>
          <li>
            Tap <q>Add</q> in the top right corner.
          </li>
        </ol>

        <h3>iOS 12 or older</h3>
        <ol>
          <li>
            Tap the <q>Share/Send</q> icon
            <ArrowUpOnSquareIcon /> on the bottom menu bar.
          </li>
          <li>
            Tap <q>Add to Home Screen</q>
            <PlusIcon /> in the list of gray icons at the bottom of your screen.
            You may need to scroll the list to the right.
          </li>
          <li>
            Tap <q>Add</q> in the top right corner.
          </li>
        </ol>

        <h3>Chrome browser</h3>
        <p>
          Clexi works in Chrome, but Chrome does not offer a way to add a web
          page to your Home Screen. We recommend you use Safari to add Clexi to
          your home screen.
        </p>
      </details>

      <details>
        <summary>
          <h2>Android Users</h2>
        </summary>

        <h3>Chrome browser</h3>
        <ol>
          <li>
            Tap the 3 dots <EllipsisVerticalIcon /> on the top right.
          </li>
          <li>
            Scroll down and tap on <q>Add to Home Screen</q>.
          </li>
        </ol>

        <h3>Microsoft Edge browser</h3>
        <ol>
          <li>
            Tap the 3 dots <EllipsisVerticalIcon /> at the bottom of your
            screen.
          </li>
          <li>
            Tap the <q>Add to Screen</q> icon.
          </li>
          <li>
            Select <q>Add</q> and <q>Add</q> again following the prompts.
          </li>
        </ol>

        <h3>Firefox browser</h3>
        <ol>
          <li>
            Tap the 3 dots <EllipsisVerticalIcon /> on your screen.
          </li>
          <li>
            Select <q>Add to Home screen</q>.
          </li>
          <li>
            Follow the prompts to tap <q>Add</q> and <q>Add</q> again.
          </li>
        </ol>
      </details>
    </main>
  )
}

export default Install
